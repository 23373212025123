<template>
    <div class="mt-6 md:mt-16 px-6 md:px-16">
        <label for="title" class="font-bold text-40px uppercase tracking-wider block">What's your event called?</label>
        <div class="relative font-work-sans mt-4">
            <label for="title" class="text-sm tracking-wider text-gray-custom absolute block top-0"><font-awesome-icon :icon="camIcon" class="text-lg pr-1 align-top"></font-awesome-icon> Your event name</label>
            <input type="text" name="title" id="title" class="bg-transparent w-full md:w-7/12 outline-none border-b border-gray-custom-2 font-work-sans-medium tracking-wider text-xl" v-on:focus="inputFocus" v-on:blur="inputBlur" v-model="preset.title" />
        </div>

        <div class="flex flex-wrap -mx-2 mt-8 md:mt-16">

            <div class="w-full md:w-1/2 px-2 mb-4" v-for="step in steps" v-bind:key="step.name">
                <div class="bg-black h-40 lg:h-48 text-4xl uppercase text-white font-bold text-center cursor-pointer tracking-wider border-b-3 border-turquoise relative" v-on:click="$router.push({path: getStepUrl(step.path)})">
                    <div class="relative top-50 left-50 -translate-50">{{step.title}}</div>
                    <font-awesome-icon :icon="checkIcon" class="text-3xl font-black absolute right-0 bottom-0 text-turquoise pr-3 pb-3" v-if="step.completed"></font-awesome-icon>
                </div>
            </div>

        </div>

        <div class="text-center mt-10 mb-12 text-3xl tracking-wider font-bold">
            <a href="https://www.boothclub.com/design-guide/" target="_blank" class="pb-2 border-b border-white">View our design guide for tips</a>
        </div>

    </div>
</template>

<script>
    import { faCamera, faCheck } from '@fortawesome/free-solid-svg-icons';

    export default {
        name: 'Steps',
        props: {
            preset: Object,
            steps: Array
        },
        methods: {
            inputFocus: function(event) {
                if( event.target.value === '' ) {
                    event.target.previousElementSibling.style.top = '-24px';
                }
            },
            inputBlur: function(event) {
                if( event.target.value === '' ) {
                    event.target.previousElementSibling.style.top = '0';
                }
            },
            getStepUrl: function(step_path) {
                return ( typeof this.$route.params.id !== 'undefined' ) ? this.$route.params.id+'/'+step_path : 'add-preset/'+step_path;
            }
        },
        computed: {
            camIcon: function() {
                return faCamera;
            },
            checkIcon: function() {
                return faCheck;
            }
        },
        mounted: function() {
            if( this.preset.title !== '' ) {
                document.getElementById('title').previousElementSibling.style.top = '-24px';
            }
        }
    }
</script>

<style scoped>
    label {
        transition: top 0.2s ease-out;
    }
</style>